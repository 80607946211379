import React from "react";
import "./MoviePlayer.css";
import movie from '../assets/movie-furo.mp4';

export default function MoviePlayer() {
    return (
        <div>
            <video autoPlay muted loop playsInline className="MoviePlayerVideo">
                <source src={movie} type="video/mp4" />
            </video >
        </div>
    );
}