import React from "react";
import "./Darts.css";
import { Typography } from '@mui/material';

export default function Darts() {
    return (
        <div className="Darts">
            <div className="DartsTitle">
                <Typography variant="h1" component="div" gutterBottom>
                    Darts
                </Typography>
            </div>
            <Typography variant="body1" component="div" gutterBottom>
                Reserveer je dartsbaan bij FURO voor 90 min.
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                Doe dit via Resengo of informeer gewoon ter plaatse naar de nog beschikbare tijdslots.
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                Er zijn 4 dartsbanen die elk de naam dragen van de sponsor :
            </Typography>
            <hr
                style={{
                    color: "#335723",
                    backgroundColor: "#335723",
                    height: 0.2,
                    width: "50%",
                }}
            />
            <Typography variant="h6" component="div" gutterBottom>
                1. Mighty Mike (Michael van Gerwen)
            </Typography>
            <Typography variant="h6" component="div" gutterBottom>
                2. Dancing Dimi (Dimitri van den bergh)
            </Typography>
            <Typography variant="h6" component="div" gutterBottom>
                3. The Hurricane (Kim Huybrechts)
            </Typography>
            <Typography variant="h6" component="div" gutterBottom>
                4. The Queen of the Palace (Fallon Sherrock)
            </Typography>
            <hr
                style={{
                    color: "#335723",
                    backgroundColor: "#335723",
                    height: 0.2,
                    width: "50%",
                }}
            />
            <Typography variant="body1" component="div" gutterBottom>
                • Dartsbaan beschikbaar voor 2 tot 4 personen
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                • Kostprijs : € 8,-/baan voor 90 min (ter plaatse af te rekenen bij ontvangst van de pijltjes + scoretablet (waarborg : 1 ID-kaart)
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                • 6 verschillende games zijn duidelijk beschreven beschikbaar ; je kan direct van start gaan
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                • Je tafeltje of hoekje om vooraf of achteraf iets te eten of na te genieten is automatisch ook gereserveerd
            </Typography>
        </div>
    );
}