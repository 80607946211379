import React from "react";
import "./Contact.css";
import { Typography } from '@mui/material';

export default function Contact() {
    return (
        <div className="Contact">

            <div className="ContactTitle">
                <Typography variant="h1" component="div" gutterBottom>
                    Contact
                </Typography>
            </div>
            <Typography variant="h5" component="div" gutterBottom>
                FURO : drinks   -   dinner   -   darts
            </Typography>
            <a href={"https://www.google.be/maps/place/Markt+79,+2440+Geel"} target="_blank">
                <Typography variant="body1" component="div" gutterBottom>
                    Markt 79, 2440 Geel
                </Typography>
            </a>
            <a href={"tel:014896986"}>
                <Typography variant="body1" component="div" gutterBottom>
                    014/89.69.86
                </Typography>
            </a>
            <a href={"mailto:info@restoFURO.be"}>
                <Typography variant="body1" component="div" gutterBottom>
                    info@restoFURO.be
                </Typography>
            </a>
            <div className="ContactLogo" />
            <div className="SponsorsLogo" />
        </div >
    );
}