import { useRef } from "react";
import './App.css';
import AppHeader from "./components/AppHeader";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MoviePlayer from './components/MoviePlayer';
import Concept from "./components/Concept";
import Apero from './components/Apero';
import Drinks from './components/Drinks';
import Dinner from './components/Dinner';
import Darts from './components/Darts';
import Contact from './components/Contact';

const theme = createTheme({
  palette: {
    primary: {
      light: '#6d975b',
      main: '#497d33',
      dark: '#335723'
    },
    secondary: {
      light: '#f3e39b',
      main: '#f0dd83',
      dark: '#a89a5b'
    }
  },
  typography: {
    fontFamily: 'Quicksand'
  }
});

function App() {
  const movieRef = useRef(null)
  const aperoRef = useRef(null)
  const drinksRef = useRef(null)
  const dinnerRef = useRef(null)
  const dartsRef = useRef(null)
  const contactRef = useRef(null)

  const scrollToMovie = () => {
    movieRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  const scrollToApero = () => {
    aperoRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  const scrollToDrinks = () => {
    drinksRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  const scrollToDinner = () => {
    dinnerRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  const scrollToDarts = () => {
    dartsRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ float: "left", clear: "both" }} ref={movieRef} />
      <AppHeader scrollToMovie={scrollToMovie} scrollToApero={scrollToApero} scrollToDrinks={scrollToDrinks} scrollToDinner={scrollToDinner} scrollToDarts={scrollToDarts} scrollToContact={scrollToContact} />
      <MoviePlayer />
      <Concept />
      <div style={{ float: "left", clear: "both" }} ref={aperoRef} />
      <Apero />
      <div style={{ float: "left", clear: "both" }} ref={drinksRef} />
      <Drinks />
      <div style={{ float: "left", clear: "both" }} ref={dinnerRef} />
      <Dinner />
      <div style={{ float: "left", clear: "both" }} ref={dartsRef} />
      <Darts />
      <div style={{ float: "left", clear: "both" }} ref={contactRef} />
      <Contact />
    </ThemeProvider>
  );
}

export default App;
