import React from "react";
import "./Concept.css";
import { Typography } from '@mui/material';

export default function Concept() {
    return (
        <div className="Concept">
            <div className="ConceptLogo" />
            <Typography variant="h5" component="div" gutterBottom>
                Lach, speel, drink, eet, feest en wees gelukkig !
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                FURO is een uniek brasserieconcept dat gebouwd is rond de aanstekelijke darts-beleving
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                Reserveer je professionele dartsbaan mèt statafel, met enkele vrienden, en maak er samen een topavondje van.
                Geniet van  verzorgde drankjes en hapjes, terwijl de darts hun weg naar de overwinning zoeken.
                Vóór of na het spel voorzien we mogelijk ook nog een zittafeltje waar je gezellig kan tafelen en napraten.
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                Je kan ook gewoon iets komen drinken of eten natuurlijk, zonder te spelen.
                We ontvangen je altijd met de glimlach !
            </Typography>
        </div >
    );
}