import React from "react";
import "./Dinner.css";
import { Typography } from '@mui/material';
import dinner1 from '../assets/dinner-1.png'
import dinner2 from '../assets/dinner-2.png'
import dinner3 from '../assets/dinner-3.png'
import dinner4 from '../assets/dinner-4.png'
import dinner5 from '../assets/dinner-5.png'
import dinner6 from '../assets/dinner-6.png'
import dinner7 from '../assets/dinner-7.png'

export default function Dinner() {
    return (
        <div className="Dinner">
            <div className="DinnerTitle">
                <Typography variant="h1" component="div" gutterBottom>
                    Dinner
                </Typography>
            </div>
            <img src={dinner1} alt="dinner1" className="DinnerImage" />
            <img src={dinner2} alt="dinner2" className="DinnerImage" />
            <img src={dinner3} alt="dinner3" className="DinnerImage" />
            <img src={dinner4} alt="dinner4" className="DinnerImage" />
            <img src={dinner5} alt="dinner5" className="DinnerImage" />
            <img src={dinner6} alt="dinner6" className="DinnerImage" />
            <img src={dinner7} alt="dinner7" className="DinnerImage" />
        </div>
    );
}