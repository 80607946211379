import React from "react";
import "./Apero.css";
import { Typography } from '@mui/material';
import apero1 from '../assets/apero-1.png'
import apero2 from '../assets/apero-2.png'

export default function Apero() {
    return (
        <div className="Apero">
            <div className="AperoTitle">
                <Typography variant="h1" component="div" gutterBottom>
                    Apero
                </Typography>
            </div>
            <img src={apero1} alt="apero1" className="AperoImage" />
            <img src={apero2} alt="apero2" className="AperoImage" />
        </div>
    );
}